<template>
  <v-row>
    <v-col sm="12" cols="12">
      <v-card
          flat
          class="pa-3 mt-6"
          :loading="marketLoading"
          :disabled="marketLoading"
      >
        <v-card-text class="d-flex">
          <v-avatar
              rounded
              size="120"
              class="me-6"
          >
            <v-img v-show="!marketLoading" :src="marketDataLocale.icon||defaultAvatar"></v-img>
          </v-avatar>

          <!-- upload photo -->
          <div>
            <v-btn
                color="primary"
                class="me-3 mt-5"
                @click="$refs.refInputIcon.click()"
            >
              <v-icon class="d-sm-none">
                {{ icons.mdiCloudUploadOutline }}
              </v-icon>
              <span class="d-none d-sm-block">{{ $t("Upload new photo") }}</span>
            </v-btn>

            <input
                v-on:change="onFileChange($event,'icon')"
                ref="refInputIcon"
                type="file"
                accept=".jpeg,.png,.jpg,GIF"
                :hidden="true"
            />

            <p class="text-sm mt-5">
              {{ $t('Allowed JPG, GIF or PNG. Max size of 800K') }}
            </p>
          </div>
        </v-card-text>

        <v-card-text>
          <v-form ref="form" class="multi-col-validation mt-6">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                    v-model="marketDataLocale.title"
                    :label="$t('Store name')"
                    dense
                    outlined
                    :rules="[validators.required, validators.urlBlockValidator, validators.lengthValidator(marketDataLocale.title, 4)]"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-textarea
                    outlined
                    v-model="marketDataLocale.description"
                    :label="$t('Description')"
                    :rules="[validators.required, validators.urlBlockValidator, validators.lengthValidator(marketDataLocale.description, 30)]"
                ></v-textarea>
              </v-col>


              <v-img class="ma-3" max-height="150" max-width="900" v-show="!marketLoading" :src="marketDataLocale.banner||defaultBanner"></v-img>

              <!-- upload photo -->
              <div>
                <v-btn
                    color="primary"
                    class="me-3 mt-5"
                    @click="$refs.refInputBanner.click()"
                >
                  <v-icon class="d-sm-none">
                    {{ icons.mdiCloudUploadOutline }}
                  </v-icon>
                  <span class="d-none d-sm-block">{{ $t("Upload new photo") }}</span>
                </v-btn>

                <input
                    v-on:change="onFileChange($event, 'banner')"
                    ref="refInputBanner"
                    type="file"
                    accept=".jpeg,.png,.jpg,GIF"
                    :hidden="true"
                />

                <p class="text-sm mt-5">
                  {{ $t('Allowed JPG, GIF or PNG. Max size of 800K') }}
                </p>
              </div>

              <v-col cols="12">
                <v-btn
                    color="primary"
                    class="me-3 mt-4"
                    @click.prevent="updateMarket"
                >
                  {{ $t('Save changes') }}
                </v-btn>
                <v-btn
                    color="secondary"
                    outlined
                    class="mt-4"
                    type="reset"
                    @click.prevent="resetForm"
                >
                  {{ $t('Cancel') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {computed, getCurrentInstance, ref} from "@vue/composition-api";
import {mdiAlertOutline, mdiCloudUploadOutline, mdiPlus} from "@mdi/js";
import store from "@/store";
import {lengthValidator, required, slug, urlBlockValidator} from "@core/utils/validation";
import axios from "@axios";

export default {
  name: "MarketSettings",
  setup() {
    const vm = getCurrentInstance().proxy
    const form = ref(null)
    const errors = ref({})
    const onFileChange = (e, field) => {
      if (e.target.files[0].size > 800000) {
        vm.$notify({
          group: 'deals',
          title: vm.$t("Image too large"),
          text: vm.$t("Allowed max size of 800K"),
          type: "error"
        })
        return;
      }
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      getBase64(files[0], field)
    }
    const getBase64 = (file, field) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        updateImg(reader.result, field)
      };
      reader.onerror = function (error) {
        // console.log('Error: ', error);
      };
    }
    const updateImg = (base64, field) => {
      marketDataLocale.value = {...Object.assign(marketDataLocale.value, {[field]: base64})}
    }

    const marketDataLocale = ref({})
    const resetForm = () => {
      marketDataLocale.value = {...store.state.market.loading}
    }
    const marketLoading = computed(() => {
      marketDataLocale.value = Object.assign({}, store.state.market.market, marketDataLocale.value)
      return store.state.market.loading
    })

    const updateMarket = () => {
      if (!form.value.validate()) {
        return
      }
      errors.value = {}
      axios.post('update-market', marketDataLocale.value)
          .then(res => {
            store.dispatch('market/getMarket')
            vm.$notify({
              group: 'deals',
              title: vm.$t("Store Update"),
              text: vm.$t("Store updated successfully"),
              type: "success"
            })
          })
          .catch(err => {
            if (err.response.data.errors) {
              errors.value = err.response.data.errors
              vm.$notify({
                group: 'deals',
                title: vm.$t("Error"),
                text: err.response.data.errors.title[0],
                type: "error"
              })
              setTimeout(() => errors.value = {}, 5000)
            } else {
              vm.$notify({
                group: 'deals',
                title: vm.$t("Error"),
                text: vm.$t("Something went wrong"),
                type: "error"
              })
            }
          })
    }
    return {
      errors,
      marketDataLocale,
      resetForm,
      onFileChange,
      updateMarket,
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiPlus
      },
      defaultAvatar: require('@/assets/images/avatars/1.png'),
      defaultBanner: require('@/assets/images/app/Avatar.jpeg'),
      marketLoading,
      form,
      validators: {
        required,
        lengthValidator,
        urlBlockValidator
      },
    }
  },
}
</script>
