<template>
  <div>
    <account-role-title role="Store" />
    <!-- <breadcrumbs /> -->
    <span class="title d-block mb-2">{{ $t("Store Account") }}</span>
    <!-- tabs -->
    <v-tabs
        v-model="tab"
        show-arrows
    >
      <v-tab
          v-for="tab in tabs"
          :key="tab.icon"
          @click="$router.push({name:'user-market', params:{tab:tab.route}, query:{t:new Date().getTime()}})"
          :disabled="tab.route == 'payout-methods' && !$store.getters['market/market']"
      >
        <v-icon
            size="20"
            class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(tab, index) in tabs" :key="index">
        <!-- tab component -->
        <component :is="tab.component" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import {
  mdiAccountOutline, mdiLockOpenOutline, mdiInformationOutline, mdiBookmarkOutline, mdiBellOutline, mdiStore, mdiCreditCard,
} from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import MarketSettings from './MarketSettings'
import store from "@/store";
import { useRouter } from '@core/utils'
import PayoutMethods from "@/views/cabinet/store/PayoutMethods";

export default {
  components: {
    MarketSettings
  },
  setup() {
    const tabs = [
      { title: 'Store settings', icon: mdiStore, route: 'settings', component: MarketSettings },
      { title: 'Payout methods', icon: mdiCreditCard, route: 'payout-methods', component: PayoutMethods },
    ]
    const { route, router } = useRouter()
    const tab = ref(tabs.findIndex(tab => tab.route === route.value.params.tab))

    store.dispatch('market/getMarket')

    watch(() => route.value.params.tab, (newTab) => {
      tab.value = tabs.findIndex(tab => tab.route === newTab)
      if(!store.getters['market/market']) {
        router.push({name: 'user-market', params:{tab:'settings'}, query:{t:new Date().getTime()}})
      }
    })

    return {
      tab,
      tabs,
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiBookmarkOutline,
        mdiBellOutline,
      },
    }
  },
}
</script>
<style scoped>
.v-item-group {
  background-color: transparent !important;
}
</style>
