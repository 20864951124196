<template>
  <v-dialog v-model="isDialogVisible" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          v-bind="attrs"
          v-on="on"
          icon
      >
        <v-icon>{{icons.mdiPencilOutline}}</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t('Edit Payout Method') }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <template>
            <v-skeleton-loader
                v-show="loading"
                type="article, actions"
            ></v-skeleton-loader>
            <component v-show="!loading" ref="dialogForm" :is="payoutMethods[payoutMethodId]"></component>
          </template>
        </v-container>
      </v-card-text>

      <v-card-actions v-show="!loading">
        <v-spacer></v-spacer>
        <v-btn
            color="error"
            outlined
            @click="isDialogVisible = false"
            :disabled="loading"
        >
          {{ $t('Close') }}
        </v-btn>
        <v-btn
            color="primary"
            @click="updatePayoutMethod"
            :loading="loading"
            :disabled="loading"
        >
          {{ $t('Update') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {ref, getCurrentInstance, watch} from '@vue/composition-api'
import axios from "@axios";
import {mdiPencilOutline} from "@mdi/js";
import BankForm from "@/views/cabinet/store/Payout/BankForm";
import PaypalForm from "@/views/cabinet/store/Payout/PaypalForm";
import SwiftForm from "@/views/cabinet/store/Payout/SwiftForm";
import WiseForm from "@/views/cabinet/store/Payout/WiseForm";
import store from "@/store";

export default {
  props: {
    id: Number,
    payoutMethodId: {
      type: String,
      required: true
    },
  },
  components: {
    BankForm,
    SwiftForm,
    PaypalForm,
    WiseForm
  },
  setup(props) {
    const vm = getCurrentInstance().proxy
    const isDialogVisible = ref(false)
    const loading = ref(true)
    const dialogForm = ref();
    const getPayout = () => {
      axios.get(`payout-method/${props.id}`).then(res => {
        Object.assign(dialogForm.value.formInputs, res.data)
        loading.value = false
      }).catch(e => {
        vm.$notify({
          group: 'deals',
          title: vm.$t('Edit Payout'),
          type: 'error',
          text: vm.$t("Something went wrong"),
        })
        loading.value = false
      })
    }
    watch(isDialogVisible, (newVal) => {
      if(newVal) getPayout()
    })
    const updatePayoutMethod = () => {
      if (!dialogForm.value.validate()) {
        return
      }
      loading.value = true
      let payoutData = dialogForm.value.formInputs
      let data = {
        payoutData,
        _method: 'put'
      }
      axios.post(`payout-method/${props.id}`, data)
          .then(res => {
            store.dispatch('market/getPayoutMethodList')
            vm.$notify({
              group: 'deals',
              title: vm.$t('Payout'),
              type: 'success',
              text: vm.$t("payment method successfully updated"),
            })
            loading.value = false
            isDialogVisible.value = false
          })
          .catch(e => {
            vm.$notify({
              group: 'deals',
              title: vm.$t('Update Payout'),
              type: 'error',
              text: vm.$t("Something went wrong"),
            })
            loading.value = false
          })
    }

    return {
      payoutMethods: {
        '1': 'paypal-form',
        '2': 'bank-form',
        '3': 'swift-form',
        '4': 'wise-form'
      },
      icons: {
        mdiPencilOutline,
      },
      dialogForm,
      loading,
      isDialogVisible,
      updatePayoutMethod,
    }
  },
}
</script>
