<template>
  <v-form
      ref="form"
      v-model="valid"
      lazy-validation
  >
    <v-text-field
        outlined
        v-model="formInputs.email"
        :rules="emailRules"
        label="Wise E-mail"
        required
    ></v-text-field>
  </v-form>
</template>

<script>
import {reactive, ref} from "@vue/composition-api";
import {emailValidator} from "@core/utils/validation";

export default {
  name: "WiseForm",
  setup() {
    const valid = ref(true)
    const form = ref(null)
    const formInputs = reactive({email: ''})
    const emailRules = [v => !!v || 'Email is required', emailValidator]
    const validate = () => {
      return form.value.validate()
    }
    const cancel = () => {
      form.value.reset()
      return true
    }

    return {
      valid,
      form,
      emailRules,
      validate,
      cancel,
      formInputs
    }
  }
}
</script>
