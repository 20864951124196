<template>
  <v-form
      ref="form"
      v-model="valid"
      lazy-validation
  >
    <v-text-field
        outlined
        v-model="formInputs.email"
        :rules="emailRules"
        label="E-mail"
        required
    ></v-text-field>
    <v-text-field
        outlined
        v-model="formInputs.phone"
        :rules="phoneRules"
        label="Phone"
        required
    ></v-text-field>
    <v-autocomplete
        outlined
        v-model="formInputs.bankCountry"
        :items="countries"
        :rules="[v => !!v || 'Item is required']"
        label="Bank Country"
        required
    ></v-autocomplete>

    <v-text-field
        outlined
        v-model="formInputs.fullName"
        :rules="fullNameRules"
        label="Full Name"
        required
    ></v-text-field>

    <v-text-field
        outlined
        v-model="formInputs.swiftCode"
        :rules="swiftCodeRules"
        label="SWIFT/BIC code"
        required
    ></v-text-field>

    <v-text-field
        outlined
        v-model="formInputs.ibanAccountNumber"
        :rules="ibanAccountNumberRules"
        label="Iban Account number"
        required
    ></v-text-field>

    <div class="text-subtitle-1 font-weight-semibold mb-4">{{$t('Recipient Address')}}</div>

    <v-autocomplete
        outlined
        v-model="formInputs.country"
        :items="countries"
        :rules="[v => !!v || 'Item is required']"
        label="Country"
        required
    ></v-autocomplete>

    <v-text-field
        outlined
        v-model="formInputs.city"
        :rules="cityRules"
        label="City"
        required
    ></v-text-field>

    <v-text-field
        outlined
        v-model="formInputs.address"
        :rules="addressRules"
        label="Address"
        required
    ></v-text-field>

    <v-text-field
        outlined
        v-model="formInputs.postCode"
        :rules="postCodeRules"
        label="Post code"
        required
    ></v-text-field>
  </v-form>
</template>

<script>
import {ref} from "@vue/composition-api";
import country from 'country-list-js';
import {reactive} from "@vue/composition-api/dist/vue-composition-api";
import {emailValidator, fullNameValidator, integerValidator, phoneValidator} from "@core/utils/validation";

export default {
  name: "SwiftForm",
  setup() {
    const valid = ref(true)
    const form = ref(null)
    const formInputs = reactive({
      email: '',
      phone: '',
      bankCountry: 'United States',
      fullName: '',
      swiftCode: '',
      ibanAccountNumber: '',
      country: 'United States',
      city: '',
      address: '',
      postCode: '',
    })
    const emailRules = [v => !!v || 'Email is required', emailValidator]
    const phoneRules = [v => !!v || 'Phone is required', phoneValidator]
    const bankCountryRules = [v => !!v || 'Select Country']
    const fullNameRules = [v => !!v || 'Full Name is required', fullNameValidator]
    const swiftCodeRules = [v => !!v || 'Swift code is required']
    const IbanAccountNumberRules = [v => !!v || 'IBAN is required']
    const countryRules = [v => !!v || 'Select Country']
    const cityRules = [v => !!v || 'City is required']
    const addressRules = [v => !!v || 'Address is required']
    const postCodeRules = [v => !!v || 'Postcode is required', integerValidator]
    const cancel = () => {
      form.value.reset()
      return true
    }
    const validate = () => {
      return form.value.validate()
    }
    return {
      valid,
      form,
      emailRules,
      phoneRules,
      bankCountryRules,
      fullNameRules,
      IbanAccountNumberRules,
      swiftCodeRules,
      countryRules,
      cityRules,
      addressRules,
      postCodeRules,
      formInputs,
      cancel,
      validate,
      countries: country.names()
    }
  }
}
</script>
