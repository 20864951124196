<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          color="primary"
          v-bind="attrs"
          v-on="on"
          text
          block
      >
        {{payoutTitle}}
      </v-btn>
    </template>
    <v-card class="px-10 pt-4" :loading="loading" :disabled="loading">
      <v-card-title>
        <span class="text-h5">{{ $t('New Payout Account') }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <component ref="dialogForm" :is="payoutMethods[payoutMethodId]"></component>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            outlined
            text
            @click="cancel"
        >
          Close
        </v-btn>
        <v-btn
            color="primary"
            @click="createPayoutMethod"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {ref} from "@vue/composition-api";
import {mdiMenuDown} from "@mdi/js";
import BankForm from "@/views/cabinet/store/Payout/BankForm";
import PaypalForm from "@/views/cabinet/store/Payout/PaypalForm";
import SwiftForm from "@/views/cabinet/store/Payout/SwiftForm";
import WiseForm from "@/views/cabinet/store/Payout/WiseForm";
import axios from "@axios";
import store from "@/store";
import {getCurrentInstance} from "@vue/composition-api/dist/vue-composition-api";
export default {
  name: "NewPaymentMethodDialog",
  props: {
    payoutMethodId: {
      type: String,
      required: true
    },
    payoutTitle: {
      type: String,
      required: true
    }
  },
  components: {
    BankForm,
    PaypalForm,
    SwiftForm,
    WiseForm
  },
  setup(props) {
    const vm = getCurrentInstance().proxy
    const dialog = ref(false)
    const dialogForm = ref();
    const loading = ref(false)
    const payoutMethods = {
      '1': 'paypal-form',
      '2': 'bank-form',
      '3': 'swift-form',
      '4': 'wise-form'
    }
    const cancel = () => {
      if(dialogForm.value.cancel()) dialog.value = false
      return true
    }
    const createPayoutMethod = () => {
      if(dialogForm.value.validate()){
        loading.value = true
        let payoutData = dialogForm.value.formInputs
        axios.post('create-payout', {payoutData, type: props.payoutMethodId}).then(res => {
          store.dispatch('market/getPayoutMethodList')
          vm.$notify({
            group: 'deals',
            title: vm.$t('Payout'),
            type: 'success',
            text: vm.$t('new payout method created'),
          })
          dialog.value = false
          loading.value = false
        }).catch(err => {
          vm.$notify({
            group: 'deals',
            title: vm.$t('Payout Creation'),
            type: 'error',
            text: vm.$t('something went wrong'),
          })
          loading.value = false
        })
      }
    }
    return {
      dialog,
      dialogForm,
      icons: {
        mdiMenuDown
      },
      payoutMethods,
      loading,
      cancel,
      createPayoutMethod
    }
  }
}
</script>
