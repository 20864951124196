<template>
  <v-row>
    <v-col sm="4" md="3" class="py-5">
      <v-card class="pa-3">
        <div class="text-h6 text-center pt-1">
          {{ $t('Seller\'s internal account') }}
        </div>
        <div class="plan-banner d-flex align-center flex-wrap">
          <v-avatar
              rounded
              class="banner-avatar"
              size="43"
          >
            <v-img
                contain
                width="23"
                height="20"
                src="@/assets/images/pages/briefcase.png"
            ></v-img>
          </v-avatar>

          <div class="font-weight-medium ms-3">
            {{ $t('Balance') }}
          </div>

          <v-spacer></v-spacer>

          <div class="d-flex align-center mt-2">
            <sup>
              <span class="text-xs font-weight-medium text--primary">$</span>
            </sup>
            <sup><h1 class="text-2xl">{{ balance }}</h1></sup>
          </div>
        </div>
      </v-card>
    </v-col>
    <v-col sm="8" md="9" class="py-5">
      <v-card class="pa-3">
        <v-row justify="space-between">
          <v-col cols="auto">
            <v-card-title>
              {{ $t('Payout methods') }}
            </v-card-title>
          </v-col>
          <v-col cols="auto" class="d-flex align-center mr-4">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                >
                  {{ $t('Add payout method') }}
                  <v-icon class="pl-4">{{ icons.mdiMenuDown }}</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                    v-for="(item, index) in payoutMethodList"
                    :key="index"
                    link
                >
                  <!--                  <v-list-item-title @click="createPayoutMethod(index)">{{ item }}</v-list-item-title>-->
                  <new-payment-method-dialog :payout-method-id="index" :payout-title="item"></new-payment-method-dialog>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
        <div class="px-5"><p>
          {{ $t('Click “Select” to set a default payout method') }}</p></div>
        <v-radio-group v-model="defaultPayout" :disabled="defaultChanging" hide-details>
          <v-data-table
              disable-pagination hide-default-footer
              no-data-text="Your list is empty"
              :headers="headers"
              :items="payoutsList"
              :loading="loading"
          >
            <template #[`item.payout_method`]="{item}">
              <span>{{ item.payout_method }}</span>
              <v-tooltip
                v-if="item.messages && item.messages.length"
                bottom
                color="white"
                content-class="payout-tooltip"
                transition="scale-transition"
                open-delay="300"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="error"
                    class="pl-4"
                  >
                    {{ icons.mdiAlertOctagonOutline }}
                  </v-icon>
                </template>
                <div
                  v-for="(message, index) in item.messages"
                  :key="index"
                >
                  <span class="text-black"><b>{{ $t('Payout method problem') + ((item.messages.length - 1) > 0 ? ' ' + (index + 1) : '') }}:</b> {{ message }}</span></br>
                </div>
              </v-tooltip>
            </template>
            <template #[`item.is_default`]="{item}">
              <div class="d-flex justify-space-between">
                <v-radio
                  :key="item.id"
                  :value="item.id"
                  @click="changeDefault(item.id)"
                >
                  <template v-slot:label>
                    <span :class="{'font-weight-semibold': defaultPayout==item.id}">{{defaultPayout==item.id?'Selected':'Select'}}</span>
                  </template>
                </v-radio>
                <div>
                  <edit-payout :id="item.id" :payout-method-id="item.payout_type.toString()"></edit-payout>
                  <delete-payout-method :id="item.id"></delete-payout-method>
                </div>
              </div>
            </template>
          </v-data-table>
        </v-radio-group>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {computed, onBeforeMount, ref} from "@vue/composition-api";
import store from "@/store";
import {mdiMenuDown, mdiPencilOutline, mdiTrashCanOutline, mdiAlertOctagonOutline} from "@mdi/js";
import NewPaymentMethodDialog from "@/views/cabinet/store/Payout/NewPaymentMethodDialog";
import axios from "@axios";
import {getCurrentInstance} from "@vue/composition-api/dist/vue-composition-api";
import deletePayoutMethod from "@/views/cabinet/store/Payout/DeletePayoutMethod";
import editPayout from "@/views/cabinet/store/Payout/EditPayout";

export default {
  name: "PayoutMethods",
  components: {
    NewPaymentMethodDialog,
    deletePayoutMethod,
    editPayout
  },
  setup() {
    onBeforeMount(() => {
      store.dispatch('market/getStoreBalance')
      store.dispatch('market/getPayoutMethodList')
      store.dispatch('market/getDefaultPayoutMethodList')
    })
    const defaultPayout = ref()
    const vm = getCurrentInstance().proxy
    const defaultChanging = ref(false)
    const balance = computed(() => store.state.market.balance)
    const payoutMethodList = computed(() => store.state.market.payoutMethodList)
    const loading = computed(() => store.state.market.payoutsLoading)
    const payoutsList = computed(() => {
      if(store.state.market.payoutsList.length) {
        let defaultP = store.state.market.payoutsList.filter(el => el.is_default == 1)
        if(defaultP.length) defaultPayout.value = defaultP[0].id
      }
      return store.state.market.payoutsList
    })
    const payoutDialogOpen = ref({})
    const createPayoutMethod = (id) => {
      payoutDialogOpen.value = {[id]: true}
    }
    const changeDefault = (id) => {
      defaultChanging.value = true
      axios.post('default-payout-method', {id: id}).then(res => {
        store.dispatch('market/getPayoutMethodList').then(defaultChanging.value = false)
      }).catch(err => {
        vm.$notify({
          group: 'deals',
          title: vm.$t('Default Payment Method'),
          text: vm.$t('something went wrong'),
          type: 'error'
        })
        store.dispatch('market/getPayoutMethodList').then(defaultChanging.value = false)
      })
    }
    return {
      changeDefault,
      balance,
      payoutMethodList,
      payoutDialogOpen,
      defaultPayout,
      defaultChanging,
      createPayoutMethod,
      icons: {
        mdiMenuDown,
        mdiPencilOutline,
        mdiTrashCanOutline,
        mdiAlertOctagonOutline
      },
      payoutsList,
      loading,
      headers: [
        {text: 'ADDED', value: 'created_at', sortable: false, width: 100},
        {text: 'PAYOUT METHOD', value: 'payout_method', sortable: false, width: 120},
        {text: 'PREVIEW', value: 'email', sortable: false, width: 100},
        {text: '', value: 'is_default', sortable: false, width: 100}
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.plan-banner {
  background-color: rgba(145, 85, 253, 0.12);
  padding: 0.563rem 0.688rem;
  border-radius: 4px;
  margin: 0.875rem 0 1.125rem 0;

  .banner-avatar {
    border: solid 1px var(--v-primary-base);
    border-radius: 4px;
  }

  sup {
    h1 {
      margin-top: 0.5rem;
    }
  }
}
</style>